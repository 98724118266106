.bg-c-green-light {
	background-color: vars.$green-c-light;
}

.bg-transparent-60 {
	background-color: rgba(255, 255, 255, .6);
}

.bg-transparent-90 {
	background-color: rgba(255, 255, 255, .9);
}

//.bg-gray-f4 {
//	background-color: vars.$gray-c-f4 !important;
//}

//.bg-c-grey {
//	background-color: vars.$grey !important;
//}

.bg-c-gray-100 {
	background-color: vars.$gray-100 !important;
}

.bg-c-gray-75 {
	background-color: vars.$gray-c-75 !important;
}

.bg-c-gray-50 {
	background-color: vars.$gray-c-50 !important;
}

.bg-c-rgba-light {
	background-color: vars.$gray-c-50 !important;
	
	&-darker {
		background-color: vars.$gray-c-75 !important;
	}
}

.bg-c-main-gradient {
	background: vars.$bg-c-main-gradient;
}

.bg-rgba-danger-light {
	background-color: rgba(vars.$red, .1);
}

.bg-c-gray-on-hover {
	&:hover {
		background: vars.$gray-c-75;
	}
}