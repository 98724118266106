.badge {
	user-select: none;
	z-index: 10;
	
	&.badge-circle-sm {
		//font-size: .75rem;
		width: 1rem;
		height: 1rem;
	}
	
	&.badge-up {
		top: 0;
		right: -0.1rem;
	}
}

.badge-c {
	padding: .25rem .75rem;
	border-radius: 100px;
}
