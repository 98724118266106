.radio {
	label {
		cursor: pointer;
		
		&::before {
			width: 1.5rem;
			height: 1.5rem;
			background: vars.$white;
			margin-right: .75rem;
		}
		
		&::after {
			border: 6px solid transparent;
			height: 1.5rem;
			width: 1.5rem;
			left: 0;
			background-clip: content-box;
			transform: scale(1.5);
		}
	}
	
	@extend .c-hover-opacity;
}