.border-c-transparent {
	border-color: transparent;
}

.border-bottom-transparent {
	border-bottom-color: transparent !important;
}

//.border-c-gray-05 {
//	border: solid rgba(199, 207, 214, .5);
//}
