@use '../variables/variables' as vars;

.checkbox {
	width: 1.5rem;
	height: 1.5rem;
	
	&:hover label:before {
		box-shadow: vars.$box-shadow;
	}
	
	label {
		&:before {
			background: vars.$white;
			border: vars.$blue-c-light 1px solid !important;
			width: 100%;
			height: 100%;
		}
		
		&:after {
			border-width: 0;
			left: 50% !important;
			top: 45% !important;
			transform: translate(-50%, -50%) rotate(-45deg) !important;
		}
	}
	
	input:disabled  {
		& ~ label {
			opacity: .5;
			&::after {
				border-color: vars.$blue-c-light !important;
			}
		}
	}
}
