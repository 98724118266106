@use 'src/styles/custom-styles-frest/variables/variables.scss' as *;
@use 'src/styles/custom-styles-frest/style' as *;


.table {
	table-layout: fixed;
	margin-bottom: 0;
	
	tr {
		font-size: .9rem;
		border-radius: inherit;
		transition: $transition-c;
	}
	
	tr:last-child {
		border-bottom: 1px solid $table-border-color;
	}
	
	tr + tr {
		border-top: 1px solid $table-border-color;
	}
	
	&.table-c-footer::after {
		content: '';
		display: block;
		height: .75rem;
	}
	
	th, td {
		&:first-child {
			padding-left: 1.5rem;
			
		}
	}
	
	th, td {
		&:last-child {
			padding-right: 1.5rem;
		}
	}
	
	thead {
		text-transform: unset;
		
		th {
			position: relative;
			padding: 1rem;
			letter-spacing: unset;
			border-bottom: $table-border-width solid $table-border-color;
			font-weight: 500;
		}
		
		.c-sort-chevrons {
			position: relative;
			user-select: none;
			display: inline-block;
			
			&::after, &::before {
				position: absolute;
				font-family: "boxicons", serif;
				top: calc(50% - 1.1em);
				right: -.25rem;
				font-size: 1.1rem;
				color: $gray-c-75;
			}
			
			&::before {
				content: "\e9b9";
			}
			
			&::after {
				content: "\e9ac";
				padding-top: .9rem;
			}
			
			&.up::before, &.down::after {
				color: $body-color;
			}
		}
	}
	
	tbody {
		tr {
			@extend .hover-c-gray-50;
      &.hide-table-row-hover {
        background-color: #e8ebef !important;
      }
		}
	}
	
	tbody + tbody {
		border-top: $table-border-width solid $table-border-color;
	}
	
	td {
		border-radius: inherit;
		
		border: unset;
		padding: .75rem 1rem;
	}
	
	&.c-table-cell-sm {
		td {
			padding-bottom: .25rem;
			padding-top: .25rem;
		}
	}
}
