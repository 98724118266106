.text-c-crop {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

//.text-c-inherit {
//	color: inherit;
//}

.text-gray-500 {
  color: vars.$gray-500 !important;
}

.text-gray-300 {
  color: vars.$gray-300 !important;
}

.text-grey-600 {
  color: vars.$gray-600;
}

//.text-gray-dark-2 {
//color: vars.$gray-c-dark-2 !important;
//}

//.text-gray-text-2 {
//	color: vars.$gray-100-2 !important;
//}

//.text-gray-1 {
//	color: vars.$gray-c-1 !important;
//}

.text-c-green-light {
  color: vars.$green-c-light;
}

.text-c-blue-dark {
  color: vars.$blue-c-dark;
}

.text-c-blue-light {
  color: vars.$blue-c-light;
}

.text-c-nowrap {
  white-space: nowrap;
}

.text-c-wrap {
  white-space: normal;
}

.text-underline {
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.text-c-transparent {
  color: transparent;
}

.text-c-transform-none {
  text-transform: none !important;
}

.text-primary-custom {
  &:hover {
    color: #468eef !important;
  }
  &:disabled {
    color: #919eaf !important;
  }
}

.text-admin {
  &-gray-light {
    color: #e3e7eb;
  }
  &-blue-light {
    color: #81a8f1;
  }
}

.c-text-pre-wrap {
  white-space: pre-wrap;
}
