@use 'src/styles/custom-styles-frest/variables/variables.scss' as vars;


.hover-c-scale {
	&:hover {
		transform: translate(-1px, -1px);
		transition: transform 50ms ease-in-out;
	}
	
	&:active {
		transform: translate(0);
	}
	
	&-lg {
		&:hover {
			transition: transform 50ms ease-in-out;
			transform: scale(1.1);
		}
	}
}

//.hover-c-item-gray {
//	&:hover {
//		background-color: vars.$gray-c-75 !important;
//	}
//}

.hover-c-gray-75 {
	&:hover {
		background-color: vars.$gray-c-75 !important;
	}
}

.hover-c-gray-50 {
	transition: vars.$transition-c;
	
	&:hover {
		background-color: vars.$gray-c-50 !important;
	}
}

.hover-c-primary {
	transition: vars.$transition-c;
	
	border: 1px solid transparent;
	
	&:hover {
		border: 1px solid vars.$primary !important;
	}
}