details {
  position: relative;
}

details .summary-title {
  user-select: none;
}

details .summary-title:hover {
  cursor: pointer;
}

details .summary-content {
  cursor: default;
}

.summary-content > div:not(:last-child) {
  margin-bottom: 1rem !important;
}

details summary {
  list-style: none;
}

details summary:focus {
  outline: none;
}

details summary:hover .summary-chevron-up svg {
  opacity: 1;
}

.summary-chevron-up svg {
  opacity: 0.5;
}

.summary-chevron-up,
.summary-chevron-down {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 35px;
  background: #F4F7FC;
}
.summary-chevron-up svg,
.summary-chevron-down svg {
  display: block;
}

summary::-webkit-details-marker {
  display: none;
}
