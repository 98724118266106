@use './variables/variables' as vars;
@import './components/bg.scss';
@import './components/text.scss';
@import './components/svg.scss';
@import './components/width.scss';
@import './components/accordion.scss';
@import './components/badge.scss';
@import './components/overflow.scss';
@import './components/form.scss';
@import './components/input.scss';
@import './components/customSwitch.scss';
@import './components/tooltip.scss';
@import './components/toastify.scss';
@import './components/opacity.scss';
@import './components/checkbox.scss';
@import './components/swiper.scss';
@import './components/loading.scss';
@import './components/imgCPlaceh.scss';
@import './components/border.scss';
@import './components/step.scss';
@import './components/table.scss';
@import './components/hover.scss';
@import './components/abs.scss';
@import './components/list.scss';
@import './components/rounded.scss';
@import './components/radio.scss';
@import './components/ldsEllipsis.scss';
@import './components/pagination.scss';
@import './components/modal.scss';
@import './components/dropdown.scss';
@import './components/gap.scss';
@import './components/shadow.scss';
@import './components/separator.scss';
@import './components/obj.scss';
@import './components/transition.scss';
@import './components/disabled.scss';
@import './components/btn.scss';
@import './components/audio.scss';

*,
*::before,
*::after {
	//outline: 1px indianred dashed
}

:root {
	--toastify-font-family: vars.$font-family-sans-serif;
	--toastify-color-info: #2f80ed;
	--toastify-color-success: #3bbc5f;
	--toastify-color-warning: #f57a08;
	--toastify-color-error: #ff4040;
	--toastify-toast-width: 400px;
}

html {
	width: 100vw;
	overflow-x: hidden;
}

body {
	position: relative;
	@extend .scroll-c;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.5;
	color: inherit;
}

button, a {
	@extend %focusVisible;
	
	&:active {
		transform: translate(1px, 1px);
	}
}

label {
	font-weight: 400;
	margin-bottom: 0.5rem;
	color: vars.$gray-300;
}

.scroll-c {
	&::-webkit-scrollbar {
		width: 0.75rem;
		height: 0.75rem;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: vars.$gray-c-75;
		
		&:hover {
			background-color: vars.$gray-100;
		}
	}
}

//.line-c-height-1-5 {
//	line-height: 1.5 !important;
//}

.no-pointer-event {
	pointer-events: none;
}

.c-hover-opacity {
	&:hover {
		transition: opacity 50ms ease-in-out;
		opacity: 0.75;
	}
}

.c-grid-cols {
	display: grid;
	//row-gap: 1rem;
	//column-gap: 2rem;
	grid-auto-flow: column;
}

.app {
	background: vars.$bg-c-main-gradient;
	width: 1390px;
	min-height: 100vh;
	min-width: 100%;
}

.min-c-touch-zone {
	@extend %minimalTouchZone;
}

.min-h-25vh {
	min-height: 25vh;
}

.chip {
	.chip-closeable {
		background: vars.$gray-100 !important;
		
		&:hover {
			background: vars.$red !important;
		}
	}
}

.max-c-size-half-viewport {
	width: max-content;
	max-width: 50vw;
	max-height: 50vh;
	overflow-y: auto;
}

.fade {
	opacity: 0;
	transition: opacity 250ms ease-in-out;
	
	&.fade-in {
		opacity: 1;
	}
	
	&.fade-out {
		opacity: 0;
	}
}

.glow-c-primary {
	//box-shadow: 0px 2px 4px rgba(90, 141, 238, 0.4);
	box-shadow: 0 2px 4px rgba(vars.$primary, 0.4) !important;
}

.c-block-ui {
	position: relative;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		//backdrop-filter: saturate(25%) !important;
		//background: repeating-linear-gradient(-45deg, rgba(gray, .1),  rgba(gray, .1) 5px, rgba(blue, .1), rgba(blue, .1) 5px) !important;
		//background: repeating-linear-gradient(-45deg, red, red 5px, blue 5px, blue 10px);
		background-image: repeating-linear-gradient(
				-45deg,
				transparent,
				transparent 5px,
				rgba(vars.$gray-600, 0.05) 5px,
				rgba(vars.$gray-600, 0.05) 10px
		);
		z-index: 9999;
		//user-select: none !important;
	}
}

.trans-c-center {
	transform-origin: 50% 50%;
}

.container {
	min-width: 1390px;
}

.align-c-center {
	justify-content: center;
	align-items: center;
}

@media (min-width: 1400px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1390px;
	}
}

.c-blur {
	//filter: blur(5px);
}

.table-layout-auto {
	table-layout: auto !important;
}

.z-c-minus-1 {
	z-index: -1;
}

.z-c-1 {
	z-index: 1;
}

.z-c-20 {
	z-index: 20;
}

.pr-2-5r {
	padding-right: 2.5rem !important;
}

.nav {
	.nav-link {
		&.active {
			color: vars.$body-color;
		}
	}
}

.c-hidden {
	display: none !important;
	visibility: hidden !important;
}

.col-c-gap1r-4 {
	width: calc(33% - 0.366rem);
}

.c-white-card {
	background-color: vars.$white;
	border-radius: 6px;
	padding: 1rem;
}

.hiddenIfNotHover {
	display: none;
}

.showInHover:hover .hiddenIfNotHover {
	display: flex;
}

.avatar {
	&-not-active {
		filter: grayscale(80%);
	}
}

.children-p-mb-0 {
	& > p {
		margin-bottom: 0;
	}
}

.rdw-link-modal {
	height: 260px !important;
}

.c-ratio-1-1 {
	aspect-ratio: 1/1;
}

.card-arrow {
	&::before {
		content: '';
		position: absolute;
		border: 15px solid transparent;
		border-bottom: 15px solid vars.$primary;
		top: -25px;
		right: 1px;
	}
}

.word-break-all {
  word-break: break-all;
}

.max-width-200 {
  max-width: 200px;
}