.gap-0-5r {
	gap: .5rem;
}

.gap-1r {
	gap: 1rem;
}

.gap-1-5r {
	gap: 1.5rem;
}

.gap-2r {
	gap: 2rem;
}

.gap-2-5r {
	gap: 2.5rem;
}

.gap-3r {
	gap: 3rem;
}
